import { Component, OnInit, HostListener, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ConnectApiService } from '../../services/connect-api.service';
import { SessionService } from '../../services/session.service';
import { Globals } from '../../shared/globals';
import { EventEmitterService } from '../../services/event-emitter.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-rightsidebar',
    templateUrl: './rightsidebar.component.html',
    styleUrls: ['./rightsidebar.component.css'],
})
export class RightSidebarComponent implements OnInit {
    @ViewChild('chatSupportComponent') chatSupportComponent: any;
    screenHeight = 200;
    constructor(
        private connectApi: ConnectApiService,
        public sessionLog: SessionService,
        public globals: Globals,
        private eventEmitterService: EventEmitterService,
        private cd: ChangeDetectorRef,
        public translate: TranslateService,
    ) { }
    ngOnInit() {
        this.onResize();
        this.eventEmitterService.updateChatSupport.subscribe((data: any) => {
            this.globals.idChatSupport = data.id_chat;
            this.globals.chatSupport.push({
                file: data.file,
                msg: data.body,
                autor: data.id_sender,
                date: Date.now(),
            });
            this.chatSupportComponent.update();
            this.cd.detectChanges();
        });
    }
    @HostListener('window:resize', ['$event'])
    onResize(event?: any) {
        this.screenHeight = window.innerHeight - 190;
        this.cd.detectChanges();
    }
    returnMessageSupport($event) {
        if ($event.file) {
            this.connectApi.uploadFileChat($event.fileToUpload, this.globals.idChatSupport).subscribe({
                next: (res: any) => {
                    this.globals.chatSupport.push({
                        file: res.content,
                        msg: $event.msg,
                        autor: this.globals.dataUser._id,
                        date: Date.now(),
                    });
                    this.chatSupportComponent.update();
                    this.globals.subject.next({
                        type: 5,
                        body: $event.msg,
                        id_sender: this.globals.dataUser._id,
                        id_receiverChatSupport: this.globals.receiverChatSupport,
                        id_chat: this.globals.idChatSupport,
                        username: this.globals.dataUser.username,
                        file: res.content,
                    });
                }, error: () => { console.log('erorrr') }
            });
        } else {
            this.globals.chatSupport.push({
                msg: $event.msg,
                autor: this.globals.dataUser._id,
                date: Date.now(),
            });
            this.chatSupportComponent.update();
            this.globals.subject.next({
                type: 5,
                body: $event.msg,
                id_sender: this.globals.dataUser._id,
                id_receiverChatSupport: this.globals.receiverChatSupport,
                id_chat: this.globals.idChatSupport,
                username: this.globals.dataUser.username,
            });
        }
        this.cd.detectChanges();
    }
    rightSideToggle() {
        this.globals.showChatSupport = !this.globals.showChatSupport;
        this.cd.detectChanges();
    }
}
