import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserCreate, CheckEmail, UserLogin } from '../models/user_model';
import { environment } from '../../environments/environment';
import moment from 'moment-timezone';

@Injectable({
    providedIn: 'root'
})
export class ConnectApiService {
    constructor(private httpClient: HttpClient) { }
    sendEmailCode(data: any) {
        return this.httpClient.post(`${environment.apiUrl}/user/emailcode`, data);
    }
    checkEmail(data: CheckEmail) {
        return this.httpClient.post(`${environment.apiUrl}/user/checksignup`, data);
    }
    forgotUser(data: any) {
        return this.httpClient.post(`${environment.apiUrl}/user/forgotpassword`, data);
    }
    changeforgotpassword(code: number, data: any) {
        return this.httpClient.put(`${environment.apiUrl}/user/changeforgotpassword/${code}`, data);
    }
    createUser(data: UserCreate) {
        return this.httpClient.post(`${environment.apiUrl}/user/signup`, data);
    }
    loginUser(data: UserLogin) {
        return this.httpClient.post(`${environment.apiUrl}/user/signin`, data);
    }
    loginUserSocial(data: any) {
        return this.httpClient.post(`${environment.apiUrl}/user/signinsocial`, data);
    }
    updateUserData(data: any) {
        return this.httpClient.put(`${environment.apiUrl}/user/userdata`, data);
    }
    updateUserPassword(data: any) {
        return this.httpClient.put(`${environment.apiUrl}/user/userpassword`, data);
    }
    refreshToken() {
        return this.httpClient.get(`${environment.apiUrl}/user/refreshdata`);
    }
    uploadVerify(data: any) {
        const input = new FormData();
        input.append('file', data);
        return this.httpClient.post(`${environment.apiUrl}/user/validate/userdocument`, input);
    }
    uploadAvatar(data: any) {
        const input = new FormData();
        input.append('file', data);
        return this.httpClient.put(`${environment.apiUrl}/user/useravatar`, input);
    }
    sendPhoneCode() {
        return this.httpClient.post(`${environment.apiUrl}/user/validate/userphonesend`, {});
    }
    checkPhone(data: any) {
        return this.httpClient.post(`${environment.apiUrl}/user/validate/userphoneconfirm`, data);
    }
    listPaymentMethods() {
        return this.httpClient.get(`${environment.apiUrl}/user/paymentmethods`);
    }
    /* getAbout() {
        return this.httpClient.get(`${environment.apiUrl}/about`);
    } */

    /* Init chat */
    getChat() {
        return this.httpClient.get(`${environment.apiUrl}/chat/chatsupport`);
    }
    getChatCompletedSupport(id: string) {
        return this.httpClient.get(`${environment.apiUrl}/chat/contentchatsupport/${id}`);
    }
    getChatCompletedTransaction(trans: string) {
        return this.httpClient.get(`${environment.apiUrl}/chat/contentchattransaction/${trans}`);
    }
    /* Fin Chat*/

    getByUsername(username: any) {
        return this.httpClient.get(`${environment.apiUrl}/user/username/${username}`);
    }

    // publications
    publishPost(data: any) {
        return this.httpClient.post(`${environment.apiUrl}/user/publications`, data);
    }
    getAdvertisementsPost(page: number, size: number, key: string) {
        if (key === '') {
            key = '0';
        }
        return this.httpClient.get(`${environment.apiUrl}/user/publications/advertisements/${page}/${size}/${key}`);
    }
    getPostTransactionUser(page: number, size: number, key: string, status: any) {
        if (key === '') {
            key = '0';
        }
        return this.httpClient.get(`${environment.apiUrl}/transactions/statustransaction/${page}/${size}/${key}/${status}`);
    }
    publishPostById(id: any) {
        return this.httpClient.get(`${environment.apiUrl}/user/publications/${id}`);
    }
    updatePost(id: any, data: any) {
        return this.httpClient.put(`${environment.apiUrl}/user/publications/${id}`, data);
    }
    deletePost(id: any) {
        return this.httpClient.put(`${environment.apiUrl}/user/publications/status/${id}`, 5);
    }
    updatePublishStatus(id: any, status: any) {
        return this.httpClient.put(`${environment.apiUrl}/user/publications/status/${id}`, status);
    }
    getListPublications(page: number, size: number, key: string, location: string, typePublications: string, typeBusiness: string) {
        if (key === '') {
            key = '0';
        }
        const timeZone = moment.tz.guess(true);
        const data: any = {
            page,
            size,
            key,
            location,
            time_zone: timeZone,
            type_publication: typePublications,
            type_business: typeBusiness,
        };
        return this.httpClient.post(`${environment.apiUrl}/publications/`, data);
    }
    firstLoadPublications(typ: any) {
        return this.httpClient.get(`${environment.apiUrl}/firstloadpublications/${typ}`);
    }
    listAllCountries() {
        return this.httpClient.get(`${environment.apiUrl}/listallcountries`);
    }
    listAllValidCurrencies() {
        return this.httpClient.get(`${environment.apiUrl}/validcurrencies`);
    }
    listTimezone() {
        return this.httpClient.get(`${environment.apiUrl}/listtimezone`);
    }
    sendCalculateEcuation(data: any) {
        return this.httpClient.post(`${environment.apiUrl}/equation`, data);
    }
    getCountryName(name: any) {
        return this.httpClient.get(`${environment.apiUrl}/countryname/${name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`);
    }
    trust(action: number, id: any) {
        return this.httpClient.post(`${environment.apiUrl}/relation/trust`, { action, id });
    }
    block(action: number, id: any) {
        return this.httpClient.post(`${environment.apiUrl}/relation/block`, { action, id });
    }
    report(data: any, id: any, text: string) {
        const input = new FormData();
        input.append('file', data);
        input.append('id', id);
        input.append('reason', text);
        return this.httpClient.post(`${environment.apiUrl}/relation/report`, input);
    }
    getReport(id: string) {
        return this.httpClient.get(`${environment.apiUrl}/relation/report/${id}`);
    }
    updateReport(id: any, text: string) {
        return this.httpClient.put(`${environment.apiUrl}/relation/report/${id}`, { text });
    }
    updateConfigUser(data: any) {
        return this.httpClient.put(`${environment.apiUrl}/user/updateconfiguser`, data);
    }
    createTransaction(data: any) {
        return this.httpClient.post(`${environment.apiUrl}/transactions/createtransaction`, data);
    }
    transactionByIdp(id: any) {
        return this.httpClient.get(`${environment.apiUrl}/transactions/transactionbyidp/${id}`);
    }
    uploadFileChat(data: any, id: string) {
        const input = new FormData();
        input.append('file', data);
        input.append('id', id);
        return this.httpClient.post(`${environment.apiUrl}/chat/uploadfile`, input);
    }
    finishTransaction(id: any, data: any) {
        return this.httpClient.put(`${environment.apiUrl}/transactions/finish/${id}`, data);
    }
    sendFeedback(id: any, data: any) {
        return this.httpClient.put(`${environment.apiUrl}/feedback/save/${id}`, data);
    }
    getListFeedback(page: number, size: number, key: string, idUser: string, status: number) {
        if (key === '') {
            key = '0';
        }
        return this.httpClient.get(`${environment.apiUrl}/feedbackpaginator/${page}/${size}/${key}/${idUser}/${status}`);
    }
    getFeedbackRatings(idUser: string) {
        return this.httpClient.get(`${environment.apiUrl}/feedbackratins/${idUser}`);
    }
    generateTwoFActorQr() {
        return this.httpClient.get(`${environment.apiUrl}/twofactor/generete`);
    }
    checkTwoFActorQr(data: any) {
        return this.httpClient.post(`${environment.apiUrl}/twofactor/check`, data);
    }
    getListTrust(page: number, size: number, key: string) {
        if (key === '') {
            key = '0';
        }
        return this.httpClient.post(`${environment.apiUrl}/relation/listtrust`, { page, size, key });
    }
    getListTrustMy(page: number, size: number, key: string) {
        if (key === '') {
            key = '0';
        }
        return this.httpClient.post(`${environment.apiUrl}/relation/listtrustmy`, { page, size, key });
    }
    getListBlock(page: number, size: number, key: string) {
        if (key === '') {
            key = '0';
        }
        return this.httpClient.post(`${environment.apiUrl}/relation/listblock`, { page, size, key });
    }
    getAddress() {
        return this.httpClient.get(`${environment.apiUrl}/wallet/getwallet`);
    }
    getTransactionsWallet(page: number, size: number, datem: string, datey: string) {
        return this.httpClient.get(`${environment.apiUrl}/wallet/gettransaction/${page}/${size}/${datem}/${datey}`);
    }
    calculateFee() {
        return this.httpClient.get(`${environment.apiUrl}/calculatefee`);
    }
    getUserWallet(data: any) {
        return this.httpClient.post(`${environment.apiUrl}/wallet/exist`, data);
    }
    transfer(data: any) {
        return this.httpClient.post(`${environment.apiUrl}/transactions/transfer`, data);
    }
    getNotification() {
        return this.httpClient.get(`${environment.apiUrl}/notifications/byuser`);
    }
    viewNotification(id: string) {
        return this.httpClient.put(`${environment.apiUrl}/notifications/updatenotification`, { id });
    }
    viewAllNotification() {
        return this.httpClient.get(`${environment.apiUrl}/notifications/viewallnotification`);
    }
    getOldWallet() {
        return this.httpClient.get(`${environment.apiUrl}/transactions/oldwallet`);
    }
    getFees() {
        return this.httpClient.get(`${environment.apiUrl}/fees`);
    }
    getMinWallet() {
        return this.httpClient.get(`${environment.apiUrl}/minwallet`);
    }
    captureReceiptPDF(id: string, idChat: string) {
        return this.httpClient.post(`${environment.apiUrl}/transactions/generatereceiptpdf`, { id, idChat });
    }
    captureSummaryPDF(id: string, idChat: string) {
        return this.httpClient.post(`${environment.apiUrl}/transactions/generatesummarypdf`, { id, idChat });
    }
    getDataImportant(userName: string) {
        return this.httpClient.get(`${environment.apiUrl}/user/summaryuser/${userName}`);
    }
    getFeedbackGroup() {
        return this.httpClient.get(`${environment.apiUrl}/feedbackgroupuser`);
    }
    sendDelete2FA(email: string) {
        return this.httpClient.get(`${environment.apiUrl}/user/deletetwofa/${email}`);
    }
    finishDelete2FA(code: string, id: string) {
        return this.httpClient.get(`${environment.apiUrl}/user/finishdeletetwofa/${code}/${id}`);
    }
    paymentMade(data: string) {
        return this.httpClient.post(`${environment.apiUrl}/transactions/paymentmade`, { 'id': data });
    }
    getTokenOnfido() {
        const headers = new HttpHeaders().set('client', 'web');
        return this.httpClient.get(`${environment.apiUrl}/user/onfido/gettoken`, { 'headers': headers });
    }
    setCheckOnfido() {
        return this.httpClient.get(`${environment.apiUrl}/user/onfido/submitcheck`);
    }
    getVerifyOnfido() {
        return this.httpClient.get(`${environment.apiUrl}/user/onfido/getstatuscheck`);
    }
    setManualVerification() {
        return this.httpClient.get(`${environment.apiUrl}/user/manualverification/request`);
    }
}
