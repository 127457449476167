<!-- ============================================================== -->
<!-- Right sidebar -->
<!-- ============================================================== -->
<!-- .right-sidebar -->
<div class="right-sidebar" [ngClass]="globals.showChatSupport ? 'shw-rside' : ''">
    <div class="rpanel-title">
        {{'body.text11' | translate}}
        <span (click)="rightSideToggle()">
            <i class="fa fa-close right-side-toggle"></i>
        </span>
    </div>
    <app-chat-window #chatSupportComponent [statusTransaction]="1" [idChat]="globals.idChatSupport" [dataContentChat]="globals.chatSupport" [dataScreenHeight]="screenHeight" (setMessage)="returnMessageSupport($event)"></app-chat-window>
</div>
