<div class="row">
    <div class="col-12">
        <div id="style-1" [ngStyle]="{'width': '100%','height': dataScreenHeight+'px', 'overflow-y': 'auto'}" #scrollMe>
        <!--<div id="style-1" [ngStyle]="{'width': '100%','height': '280px', 'overflow-y': 'auto'}" #scrollMe>-->
            <ol class="chat">
                <li *ngFor="let item of dataContentChat; trackBy: trackByFn" [ngClass]="item.autor == globals.dataUser._id ? 'self' : 'other'">
                    <div class="avatar2">
                        <img src="assets/images/icon/Grupo_1661.svg" draggable="false" />
                    </div>
                    <div class="msg">
                        <b *ngIf="applicant || responsable" class="small" [innerHTML]="item.autor == applicant[0]._id ? applicant[0].username : item.autor == responsable[0]._id ? responsable[0].username : 'Admin'"></b>
                        <a href="javascript:void(0);" *ngIf="item.file" (click)="openFile(item.file)">
                            <img class="image-fluid" [src]=" imgServer + '/uploads/chat-'+idChat+'/Thumbnail-'+item.file" style="width:100%;">
                        </a>
                        <p *ngIf="item.msg">{{item.msg}}</p>
                        <time>{{item.date | date:'yyyy-MM-dd HH:mm'}}</time>
                    </div>
                </li>
            </ol>
        </div>
        <div>
            <form class="form-horizontal" [formGroup]="chatForm" (keyup.enter)="sendMessage()" (ngSubmit)="sendMessage()">
                <div class="container">
                    <div *ngIf="file" class="row" style="border:1px solid rgb(175, 175, 175);position: relative;">
                        <button (click)="removeFile();" class="btn btn-sm btn-outline-dark remove-file">
                            <i class="fa fa-close"></i>
                        </button>
                        <div class="col-md-6">
                            <img [src]="imgURL" height="80">
                        </div>
                        <div class="col-md-6">
                            <small class="text-muted">{{'chat.text1' | translate}}</small>
                        </div>
                    </div>
                </div>
                <textarea formControlName="text" class="form-control type_msg" [placeholder]="'chat.text2' | translate">
                </textarea>
                <div class="row" style="padding-top:15px;">
                    <div class="col-4 col-md-4 text-center">
                        <span>&nbsp;</span>
                    </div>
                    <div class="col-4 col-md-4 text-center" style="padding-right:2px;">
                        <span>
                            <button type="button" class="btn btn-outline-warning-chat" style="width: 100%;"
                                (click)="fileInput.click()">
                                {{'chat.text3' | translate}}
                            </button>
                            <input (change)="onSelectFile($event)" hidden type="file" #fileInput accept=".png, .jpg, .jpeg">
                        </span>
                    </div>
                    <div class="col-4 col-md-4 text-center" style="padding-left:2px;">
                        <button type="submit" class="btn btn-warning btn-block" style="border-radius: 0rem !important;">
                            {{'chat.text4' | translate}}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>