<!-- ============================================================== -->
<!-- Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
<div class="row">
    <div class="col-12 ol-sm-12 col-md-12 col-lg-12 col-xl-12 align-self-center mt-2">
        <p style="font-family: Roboto,sans-serif !important;font-size: 16px;color: #54667a;">
            <span style="font-weight: bold;" [innerHTML]="breadcrumb1"></span>
            <span [innerHTML]="breadcrumb2"></span>
            <ngb-alert [dismissible]="false" type="danger" style="font-size:10pt;" *ngIf="globals.dataUser != undefined && globals.dataUser.identity && globals.dataUser.identity.level == 0">
                <fa-icon [icon]="icons.faTriangleExclamation"></fa-icon> {{'advertise.text333' | translate}} <a class="subbar" style="font-size: 9pt;" [routerLink]="'/settings/'">{{'advertise.text334' | translate}}</a>
            </ngb-alert>
        </p>
    </div>
</div>
<!-- ============================================================== -->
<!-- End Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
